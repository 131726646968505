

class Price {
    /** розница */
    roz = 0;
    /** оптовая */
    opt = 0;
    /** цена по запрос */
    requestPrice = false
    /** ед, измерения */
    weight = ' руб/кг'

    constructor(roz, opt, requestPrice, weight) {
        roz !== undefined && (this.roz = roz)
        opt !== undefined && (this.opt = opt)
        requestPrice !== undefined && (this.requestPrice = requestPrice)
        weight !== undefined && (this.weight = weight)
    }
}

class Product {
    name = 'Без названия'
    key = String(Math.random() * 1000)
    price = new Price(0, 0)

    constructor(name, key, price) {
        name !== undefined && (this.name = name)
        key !== undefined && (this.key = key)
        price !== undefined && (this.price = price)
    }
}

export class Products {
    products = [
        // чёрный металл
        new Product('Чёрный металл', 'black metal', new Price(18, 19)),
        new Product('Чугун', 'cast iron', new Price(18, 19)),
        new Product('Лом автомобиля', 'car scrap', new Price(18000, 19000, false, " руб/тонна")),
        // медно-латунная группа
        new Product('Медь а1-1 (блеск)', 'A1-1 copper (gloss)', new Price(740, 745)),
        new Product('Медь (шина)', 'copper (tire)', new Price(730, 735)),
        new Product('Медь а1-2 (кусок)', 'A1-2 copper (piece)', new Price(730, 735)),
        new Product('Медь (разносорт.)', 'copper (various grades)', new Price(720, 725)),
        new Product('Латунь', 'brass', new Price(420, 430)),
        new Product('Латунная стружка', 'brass shavings', new Price(0, 0, true)),
        new Product('Радиаторы медь/латунь', 'radiators copper/brass', new Price(0, 0, true)),
        new Product('Бронза', 'bronze', new Price(0, 0, true)),
        // алюминиевая группа
        new Product('Алюминий 1-1 (электротехнический)', 'aluminum 1-1 (electrical)', new Price(140, 160)),
        new Product('Алюминий 1-1 (пищевой)', '', new Price(130, 150)),
        new Product('Алюминий АД-31 (Профиль) чистый', '', new Price(120, 140)),
        new Product('Алюминий АД-31 (Профиль) грязный', '', new Price(0, 0, true)),
        new Product('Опалубка (засор по факту)', '', new Price(0, 0, true)),
        new Product('Диски', '', new Price(120, 125)),
        new Product('Алюминиевая банка', '', new Price(60, 80)),
        new Product('Алюминий (разносортный)', '', new Price(100, 110)),
        new Product('Алюминий моторка', '', new Price(100, 110)),
        new Product('Алюминий офсет', '', new Price(0, 0, true)),
        new Product('Алюминий радиаторы', '', new Price(60, 70)),
        new Product('Алюминиевая стружка (засор от 5%)', '', new Price(0, 0, true)),
        new Product('Электродвигатель (корпус алюминий)', '', new Price(0, 0, true)),
        new Product('Электродвигатель (корпус чугун)', '', new Price(0, 0, true)),
        // нержавеющая сталь
        new Product('Нержавеющая сталь габаритом 0,5х0,5х1,5 м, 10%', '', new Price(0, 0, true)),
        new Product('Нержавеющая сталь негабаритная 10%', '', new Price(0, 0, true)),
        new Product('Нержавеющая сталь 8% (в т. ч. негабаритная)', '', new Price(0, 0, true)),
        new Product('Нержавеющая сталь 6%', '', new Price(0, 0, true)),
        // свинцово-оловянная группа
        new Product('Свинец (оболочка кабеля) чистый', '', new Price(0, 0, true)),
        new Product('Свинец (переплав) грязный', '', new Price(0, 0, true)),
        new Product('Свинец (грузики)', '', new Price(0, 0, true)),
        new Product('Цинк (карбюраторный, решётки)', '', new Price(0, 0, true)),
        new Product('Цинк (карбюратор в сборе)', '', new Price(0, 0, true)),
        // лом кабеля
        // -
        // аккумуляторы
        new Product('Аккумуляторы (гель, полипропилен)', '', new Price(40, 45)),
        new Product('Аккумуляторы (эбонит)', '', new Price(30, 35)),
        // прочее
        // -
    ]

    getByKey(key) {
        const product = this.products.find(product => product.key === key || product.name === key)

        return product || new Product()
    }

    getPropsByKey(key) {
        const product = this.products.find(product => product.key === key || product.name === key) || new Product()

        const props = {
            title: product.name,
            priceRozn: product.price.roz,
            priseOpt: product.price.opt,
            weight: product.price.weight,
            requestPrice: product.price.requestPrice,
        }

        return props
    }
}